<template>

	<span ref="content" no-body>


		<!-- <b-tooltip triggers="manual" ref="tooltip" target="tooltip-button-show-event">
		<strong>Wir suchen Mitarbeiter!</strong>
		</b-tooltip> -->

			<b-navbar fixed="top" toggleable="sm">

			<b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
				<b-collapse is-nav id="nav_collapse">

				<b-navbar-nav v-b-scrollspy="200">

							<b-nav-item href="#buero">Büro</b-nav-item>
							<b-nav-item href="#projekte">Projekte</b-nav-item>
							<b-nav-item href="#team">Team</b-nav-item>
							<b-nav-item id="tooltip-button-show-event" href="#jobs">Jobs</b-nav-item>
							<b-nav-item href="#kontakt">Kontakt</b-nav-item>

				</b-navbar-nav>

					<b-navbar-nav class="ml-auto">
						<b-nav-item  @click="scrollIntoView" href="#start">RETZ ARCHITEKTEN</b-nav-item>
					</b-navbar-nav>
				</b-collapse>
			</b-navbar>


		<div class="hintergrund"> 


		<b-modal size="xl" ref="my-modal" hide-footer :title="modalProjekt.name">
		<div class="d-block text-center">
	
		</div>

	<b-card no-body class="overflow-hidden" >
		<b-row no-gutters>
		<b-col md="9">
			<img v-if="modalProjekt.pfad.length>0" :src="require(`@/assets/projects/full/${modalProjekt.pfad}`)" class="card-img-top">
		</b-col>
		<b-col md="3">

			<b-card-body >
			<b-card-text>
				<p v-if="projektDaten[modalProjekt.name]!=undefined">Baujahr: {{projektDaten[modalProjekt.name].baujahr}}</p>
				<p v-if="projektDaten[modalProjekt.name]!=undefined">Lage: {{projektDaten[modalProjekt.name].lage}}</p>

			</b-card-text>
			</b-card-body>
		</b-col>
		</b-row>
	</b-card>

		</b-modal>


			<div class="kontainer">

				<div id="start" class="willkommen">

			<swiper ref="mySwiper" :options="swiperOptions">
				<div class="title">RETZ ARCHITEKTEN</div>

					<div class="swiper-slide">
						<div class="subtitle"></div>
						<img src="@/assets/projects/full/verkleiner3/k-Edeka Markt in Kürten Dürscheid.jpg">
					</div>

					<div class="swiper-slide">
						<div class="subtitle">Konzeption</div>
						<img src="@/assets/projects/full/verkleiner3/k-Mehrfamilienhaus in Kürten-Dürscheid.jpg">
					</div>

					<div class="swiper-slide">
						<div class="subtitle">Konzeption</div>
						<img src="@/assets/projects/full/verkleiner3/k-Begegnungszentrum.jpg">
					</div>

			</swiper>

				</div>

	<b-card class="anchor ueberschrift my-4 mx-4" id="buero" title="Büro">

		<b>Seit 1973 plant und begleitet das Architekturbüro Retz Bauprojekte.</b>

		<div class="job">
		<b-card class="border-0 my-4 buero" title="Unsere Leistungen">
		<ul>
				<li>Planung: Entwurf, Genehmigung, Ausführung</li>
				<li>Projektsteuerung</li>
				<li>Bauleitung</li>
				<li>Wertermittlung</li>
				<li>Kostenmanagement</li>
						</ul>

		</b-card>

		<b-card class="border-0 my-4 buero" title="Gebäudearten">
			<ul>
				<li>Wohngebäude</li>
				<li>Wohnheime und therapeutische Einrichtungen</li>
				<li>Gewerbliche Bauten für Industrie, Handwerk und Landwirtschaft</li>
				<li>Schulbauten</li>
				<li>Kirchliche Bauten und Anlagen</li>
				<li>Kulturbauten und Denkmalpflege</li>
			</ul>

		</b-card>

		<b-card class="border-0 my-4 buero" title="Projektlagen">
			<ul>
				<li>Kürten</li>
				<li>Bergisch Gladbach</li>
				<li>Köln</li>
				<li>Gummersbach</li>
				<li>Leverkusen</li>
				<li>Wipperfürth</li>
			</ul>

		</b-card>

	</div>
				
	</b-card>

	<b-card class="ueberschrift anchor my-4 mx-4" id="projekte" title="Projekte">

			<ul class="projekte list-group list-group-horizontal align-items-stretch flex-wrap">

				<li @click="showModal(bild)" :key="index" v-for="(bild, index) in imagesForProjectsThumb" class="list-group-item border-0">
					<div class="card h-100 my-3">
						
						<div class="card-footer">
							{{bild.name}}
						</div>
						
						<img :src="require(`@/assets/projects/thumb/${bild.pfad}`)" class="card-img-top">

					</div>
				</li>
			</ul>


	</b-card>



	<b-card class="ueberschrift anchor my-4 mx-4" id="team" title="Team">

			<ul class="team list-group list-group-horizontal align-items-stretch flex-wrap">

				<li :key="index" v-for="(bild, index) in imagesForTeam" class="list-group-item border-0">
					

					<b-card  style="height:100%" class="border-1 mb-3" :title="bild.name" :sub-title="bild.funktion">
						
						<b-card-text>
								<div class="flip-box">
									<div class="flip-box-inner">
										<div class="flip-box-front">
											<img @mouseover="hoverIn()" @mouseout="hoverOut()"   :src="require(`@/assets/team/${bild.pfad}`)" class="card-img-top card-team">
										</div>
										<div class="flip-box-back">
											<p v-if="kontaktDaten[bild.name]!=undefined">{{kontaktDaten[bild.name].tel}}</p> 
										</div>
									</div>
								</div> 
						</b-card-text>

					</b-card>


				</li>
			</ul>


	</b-card>

	<!-- <div  class='welcome my-4'> <h5>Jobs</h5></div> -->

	<b-card style="padding-bottom:25px" class="ueberschrift anchor my-4 mx-4" id="jobs" title="Jobs">

		<p class="my-3">Wir suchen Bauleiter und Planer zur Verstärkung unseres Teams.</p>
		<!--<p class="my-3">Aktuell haben wir keine offenen Stellen.</p>-->

		<!-- <b-card no-body> -->

		<b-tabs  align="center" >
			
		<b-tab  title-item-class="job-nav-link" title="Bauleiter(in)" active>

			<b-card-text class="mt-3">
				
					<div class="job">	
						
				<p>Massenermittlung, Leistungsverzeichnis, Ausschreibung, Bauleitung von Wohn- und Geschäftshäusern<br>(Leistungsphasen HOAI 6-9)</p>

	<b-button v-b-toggle.collapse-1 variant="primary">

		<span class="when-open">Weniger</span>
		<span class="when-closed">Mehr</span> Infos
		</b-button>
	</div>

	<b-collapse id="collapse-1" class="mt-2">

		<div class="job">	
					

			<p><b>Einsatzort:</b>  Kürten | Projekte in Kürten, Bergisch Gladbach und Köln</p>

			
			<p><b>Ihre Aufgaben:</b><br>
				<ul>
			<li>Leistungsphasen HOAI 6-9</li>
				<ul>
				<li>Vorbereitung der Vergabe<br>
				<i>Ermitteln der Mengen und Aufstellen von Leistungsverzeichnissen</i></li>

				<li>Mitwirkung bei der Vergabe<br>
				<i>Ermitteln der Kosten und Mitwirkung bei der Auftragsvergabe</i></li>

				<li>Objektüberwachung (Bauüberwachung)<br>
				<i>überwachen der Ausführung des Objekts</i></li>

				<li>Objektbetreuung und Dokumentation<br>
				<i>überwachen der Beseitigung von Mängeln und Dokumentation des Gesamtergebnisses</i></li>

				</ul>


				</ul>
			</p>


			<p><b>Ihr Profil:</b><br>
				<ul>
					<li>Abgeschlossenes Studium als Bauingenieur oder Ausbildung in einer bauspezifischen Fachrichtung, gerne mit Weiterbildung</li>
					<li>Berufserfahrung ist wünschenswert</li>
					<li>Gute MS Office-Kenntnisse (Word und Excel)</li>
					<li>Gute Deutschkenntnisse in Wort und Schrift</li>
					<li>Idealerweise Kenntnisse der Software AVAplan</li>
				</ul>
			</p>

			<p><b>Wir bieten:</b><br>
				<ul>
					<li>Abwechslungsreiche Projekte</li>
					<li>faire Entlohnung</li>
					<li>Firmenwagen</li>
				</ul>
			</p>

			<p><b>Beginn:</b><br>
				<ul>
					<li>ab sofort</li>
				</ul>
			</p><br>
			</div>

	</b-collapse>

			</b-card-text>
		</b-tab>

		<b-tab title-item-class="job-nav-link" title="Planer(in)">
		<b-card-text class="mt-3">
				
		<div class="job">
			<p>Planung von Wohn- und Geschäftshäusern<br>(Leistungsphasen HOAI 1-5)</p>
			<b-button v-b-toggle.collapse-1 variant="primary">

		<span class="when-open">Weniger</span>
		<span class="when-closed">Mehr</span> Infos
		</b-button>
	</div>	

	<b-collapse id="collapse-1" class="mt-2">

		<div class="job">	
			
		<p><b>Einsatzort:</b>  Kürten | Projekte in Kürten, Bergisch Gladbach und Köln</p>

		
		<p><b>Ihre Aufgaben:</b><br>
			<ul>
				<li>Leistungsphasen HOAI 1-5</li>

				<ul>
				<li>Grundlagenermittlung<br>
					<i>Ermitteln der Voraussetzungen zur Lösung der technischen Aufgabe</i></li>

				<li>Vorplanung<br>
					<i>Erarbeiten der wesentlichen Teile einer Lösung der Planungsaufgabe</i></li>

				<li>Entwurfsplanung<br>
					<i>Erarbeiten der endgültigen Lösung der Planungsaufgabe</i></li>

				<li>Genehmigungsplanung<br>
					<i>Erarbeiten der Vorlagen für die erforderlichen Genehmigungen</i></li>

				<li> Ausführungsplanung<br>
					<i>Erarbeiten und Darstellen der ausführungsreifen Planungslösung</i></li>

				</ul>


			</ul>
		</p>


			<p><b>Ihr Profil:</b><br>
				<ul>
					<li>Abgeschlossenes Studium als Architekt(in) / Bauingenieur(in) oder Ausbildung in einer bauspezifischen Fachrichtung, gerne mit Weiterbildung</li>
					<li>Berufserfahrung ist wünschenswert</li>
					<li>Gute CAD-Kenntnisse (ArchiCAD)</li>
					<li>Gute MS Office-Kenntnisse (Word und Excel)</li>
					<li>Gute Deutschkenntnisse in Wort und Schrift</li>
				</ul>
			</p>

			<p><b>Wir bieten:</b><br>
				<ul>
					<li>Abwechslungsreiche Projekte</li>
					<li>faire Entlohnung</li>
				</ul>
			</p>

			<p><b>Beginn:</b><br>
				<ul>
					<li>ab sofort</li>
				</ul>
			</p><br>
			</div>

	</b-collapse>

			</b-card-text>
		</b-tab>


		<b-tab title-item-class="job-nav-link" title="bewerben">
			<b-card-text class="mt-3">
				<div class="job">	
				Ihre schriftliche Bewerbung mit Lebenslauf und Zeugniskopien richten Sie bitte an Herrn Markus Berghaus. 
				Ausschließlich im <b>PDF-Format</b> (andere Formate wie z.B. .doc werden aus Sicherheitsgründen ungelesen gelöscht)<br>
				per E-Mail:

	<a href="#" class="cryptedmail"
	data-name="info"
	data-domain="architekt-retz"
	data-tld="de"
	onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"></a>

	<br>oder postalisch an: RETZ ARCHITEKTEN | Markus Berghaus | Cliev 1 | 51515 Kürten
	</div>
			</b-card-text>
		</b-tab>

		</b-tabs>

	</b-card>


	<!-- </b-card> -->

	<b-card class="ueberschrift anchor my-4 mx-4" id="kontakt" title="Kontakt">

		<!-- <div id="kontakt" class='welcome my-4'> <h5>Kontakt</h5></div> -->
		
		<b-card class="border-0">
			<div class="job">
				Retz Architekten PartGmbB<br>
				Cliev 1<br>
				51515 Kürten<br>
				<br>
				Amtsgericht Essen, PR 2628<br>
				<br>
				<!-- USt-IdNr.: DE<br>
				<br> -->
				Tel.: 02207 9190 2 -0<br>
				Fax: 02207 9190 2 -20<br>
				E-Mail: <a href="#" class="cryptedmail"
				data-name="info"
				data-domain="architekt-retz"
				data-tld="de"
				onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"></a>

				<br>
				<br>
				Partner: Markus Berghaus, Daniel Buchholz<br>
				<br>
		</div>



			<div class="job" style="height: 500px; width: 100%">

				<l-map
				v-if="showMap"
				:zoom="zoom"
				:center="center"
				:options="mapOptions"
				style="height: 80%"
				@update:center="centerUpdate"
				@update:zoom="zoomUpdate"
				>
				<l-tile-layer
					:url="url"
					:attribution="attribution"
				/>

				<l-marker :lat-lng="withTooltip">
					<l-tooltip :options="{ permanent: true, interactive: true }">
					<div @click="innerClick">
						Retz Architekten
						<p v-show="showParagraph">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
						sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
						Donec finibus semper metus id malesuada.
						</p>
					</div>
					</l-tooltip>
				</l-marker>
				</l-map>
			</div>
		
		</b-card>

		<b-card class="border-0">
			<div class="job">
				COPYRIGHT
				Die in der Website enthaltenen Texte, Bilder und Grafiken sind urheberrechtlich geschützt. Das Copyright aller Inhalte liegt bei Retz Architekten. Für einzelne Beiträge und Bildwerke liegen die Rechte bei Autoren und Künstlern. Alle Teile der Website unterliegen dem Copyright und sind urheberrechtlich als Datensammelwerk geschützt. Alle Rechte vorbehalten. Jede Verwertung oder Übernahme von Texten und Bildern bedarf der schriftlichen Zustimmung des Copyright-Inhabers.

				Informationen zu den Seiteninhalten Externe Links: Die Inhalte externer Links werden von uns nicht geprüft. Sie unterliegen der Haftung der jeweiligen Anbieter.

				Datenschutzhinweise: Alle auf dieser Website genannten Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe ihrer Daten (vgl. § 28 BDSG).

				Unverbindlichkeit der Informationen: Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt recherchiert. Gleichwohl übernimmt der Anbieter keine Haftung für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Informationen. Die Beiträge geben die Meinungen des jeweiligen Autors wieder.
					
			</div>
		</b-card>

		<b-card class="border-0">		

			<b-button v-b-toggle.collapse-2 variant="primary">
				Datenschutzerklärung
				<span class="when-open"> schließen</span>
				<span class="when-closed"> öffnen</span> 

		</b-button>


		<b-collapse id="collapse-2" class="mt-2">
			<div class="job">
				Allgemeiner Hinweis und Pflichtinformationen
				Benennung der verantwortlichen Stelle
				Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
				Retz Architekten PartGmbB Cliev 1 51515 Kürten
				Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
				Widerruf Ihrer Einwilligung zur Datenverarbeitung
				Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
				Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
				Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
				Recht auf Datenübertragbarkeit
				Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
				Recht auf Auskunft, Berichtigung, Sperrung, Löschung
				Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
				SSL- bzw. TLS-Verschlüsselung
				Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.
				Cookies
				Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
				Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen. Solche Cookies helfen uns, Sie bei Rückkehr auf unserer Website wiederzuerkennen.
				Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schließen des Programms von selbst gelöscht werden. Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer Website zur Folge haben.
				Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste. Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden diese in dieser Datenschutzerklärung separat behandelt.
				Quelle: Datenschutz-Konfigurator von mein-datenschutzbeauftragter.de 
				</div>
		</b-collapse>


			</b-card>
		</b-card>

			<div style="height:300px;"></div>


		</div>
	</div>
				
		<footer id="footer-copyright">
			<div class="zentriert">
	
					<router-link class="impressum" to="#kontakt" @click.native="scrollFix('#kontakt')">Impressum</router-link>

			</div>
		</footer>

	</span>
</template>

<script>

const config = {
  element: 'body',
  offset: 10,
  method: 'auto',
  throttle: 100
}

import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
// import kontakt from '@/components/kontakt.vue'
import 'swiper/swiper-bundle.css'
import { forAllMixin } from '../mixins/forAllMixin.js'
// If you use Swiper 6.0.0 or higher
//import 'swiper/swiper-bundle.css'
	
export default {

 components: {
    Swiper,
	SwiperSlide,
	LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip

	// kontakt
	},
	
    data(){
        return{

			zoom: 14,
            center: latLng(51.019760, 7.206181),
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            withTooltip: latLng(51.019760, 7.206181),
            currentZoom: 14,
            currentCenter: latLng(51.019760, 7.206181),
            showParagraph: false,
            mapOptions: {
                zoomSnap: 0.5
            },
            showMap: true,

			kontaktDaten:
			{"Behlendorf, Doreen":{"email1":"info", "email2":"architekt-retz", "tel":"0 22 07 - 91 90 2 0"},
			"Berghaus, Markus":{"email1":"m.berghaus", "email2":"architekt-retz", "tel":"0 22 07 - 91 90 2 15"},
			"Berghaus, Peter":{"email1":"p.berghaus", "email2":"architekt-retz", "tel":"0 22 07 - 91 90 2 17"},
			"Berghaus, Carlotta":{"email1":"c.berghaus", "email2":"retz-architekten", "tel":"0 22 07 - 91 90 2 0"},	
			"Buchholz, Daniel":{"email1":"d.buchholz", "email2":"architekt-retz", "tel":"0 22 07 - 91 90 2 14"},
			"Deutsch, Clara":{"email1":"c.deutsch", "email2":"retz-architekten", "tel":"0 22 07 - 91 90 2 0"},	
			"Freisen, Angelika":{"email1":"a.freisen", "email2":"retz-architekten", "tel":"0 22 07 - 91 90 2 0"},
			"Harba, Shadi":{"email1":"s.harba", "email2":"retz-architekten", "tel":"0 22 07 - 91 90 2 15"},
			"Harmuth, Lothar":{"email1":"l.harmuth", "email2":"architekt-retz", "tel":"0 22 07 - 91 90 2 16"},
			"Schou-Harms, Kirsten":{"email1":"k.schou-harms", "email2":"architekt-retz", "tel":"0 22 07 - 91 90 2 16"},
			"Hellmich, Jasmin":{"email1":"j.hellmich", "email2":"retz-architekten", "tel":"0 22 07 - 91 90 2 28"},
			"Köllen, Renate":{"email1":"r.koellen", "email2":"retz-architekten", "tel":"0 22 07 - 91 90 2 29"},
			"Merl, Tobias":{"email1":"t.merl", "email2":"retz-architekten", "tel":"0 22 07 - 91 90 2 24"},
			"Pinell, Nicole":{"email1":"n.pinell", "email2":"retz-architekten", "tel":"0 22 07 - 91 90 2 27"},
			"Retz, Frederik":{"email1":"f.retz", "email2":"architekt-retz", "tel":"0 22 07 - 91 90 2 0"},
			"Retz, Michael":{"email1":"m.retz", "email2":"architekt-retz", "tel":"0 22 07 - 91 90 2 0"},
			"Schneider, Maya":{"email1":"m.schneider", "email2":"retz-architekten", "tel":"0 22 07 - 91 90 2 13"},
			"Wohlrab, Heike":{"email1":"h.wohlrab", "email2":"retz-architekten", "tel":"0 22 07 - 91 90 2 18"},
			"Wirtz, Sabine":{"email1":"s.wirtz", "email2":"retz-architekten", "tel":"0 22 07 - 91 90 2 26"},

			},

			projektDaten:
			{
			"Begegnungszentrum":{"baujahr":"Konzeption", "lage":"Odenthal",},
			"Bürogebäude in Untereschbach":{"baujahr":"2018", "lage":"Bergisch Gladbach - Untereschbach",},
			"Edeka Markt in Kürten Dürscheid":{"baujahr":"2020", "lage":"Kürten-Dürscheid",},
			"Mehrfamilienhaus in Bergisch Gladbach":{"baujahr":"2014", "lage":"Bergisch Gladbach",},
			"Mehrfamilienhaus in Kürten-Dürscheid":{"baujahr":"Konzeption", "lage":"Kürten-Dürscheid",},
			"Mehrfamilienhaus in Neschen":{"baujahr":"2014", "lage":"Odenthal-Neschen",},
			"Neubau eines Wohn- und Geschäftshauses in Kürten-Dürscheid":{"baujahr":"2013", "lage":"Kürten-Dürscheid",},
			
			},

		images: [],
		projectsFull:[],
		projectsThumb:[],
		modalProjekt:{pfad:'', name:''},
		swiperOptions: {

          autoplay: {
            delay: 4000,
            disableOnInteraction: false
          },

			effect: 'fade',
          pagination: {
			el: '.swiper-pagination',
			
          },
          // Some Swiper option/callback...
        }
        }
	},

	computed:{

	imagesForTeam(){
		var bildArray=[]

		for(var item of this.images){
				// console.log(item)

			var res1 = item.pathShort.split(".");

			var res2 = res1[0].split(";");
			
			// console.log(res2)

			bildArray.push({'pfad':item.pathShort, 'name':res2[0], 'funktion':res2[1]})
		}

		return bildArray
	},

	 	imagesForProjects(){
	 	var bildArray=[]

	 	for(var item of this.projectsFull){
	 		var res1 = item.pathShort.split(".");
	 		bildArray.push({'pfad':item.pathShort, 'name':res1[0]})
	 	}

	 	return bildArray
	 },

	imagesForProjectsThumb(){
	 	var bildArray=[]

	 	for(var item of this.projectsThumb){
	 		var res1 = item.pathShort.split(".");
	 		bildArray.push({'pfad':item.pathShort, 'name':res1[0]})
	 	}

	 	return bildArray
	}

	},

	mounted() {

		this.$nextTick(function () {

		if(this.$refs.tooltip){
			this.$refs.tooltip.$emit('open');

				setTimeout(()=>{
				this.$refs.tooltip.$emit('close');
				},5000);
		}
			// Code that will run only after the
			// entire view has been rendered
		})

		

	//this.importAll(require.context('../assets/team/', true, /\.png$/));
	this.importAll(require.context('../assets/team/', true));
	//this.importAllProjects(require.context('../assets/projects/full/', true));
	this.importAllProjectsThumbs(require.context('../assets/projects/thumb/', true));
  },

	methods:{

    zoomUpdate(zoom) {
        this.currentZoom = zoom;
        },
        centerUpdate(center) {
        this.currentCenter = center;
        },
        showLongText() {
        this.showParagraph = !this.showParagraph;
        },
        innerClick() {
        alert("Click!");
        },

	hoverIn(){
		// console.log("hoverIn")
	},

	hoverOut(){
		// console.log("hoverOut")
	},

 scrollIntoView(evt) {
        evt.preventDefault()
        const href = evt.target.getAttribute('href')
		const el = href ? document.querySelector(href) : null
		
		document.documentElement.scrollTop = 0

		// console.log(el)
        // if (el) {
		// 	console.log(el.offsetTop)
		// 	document.documentElement.scrollTop =  el.offsetTop
        //   //this.$refs.content.scrollTop = el.offsetTop
        // }
      },
 		onOpen() {
        this.$refs.tooltip.$emit('open')
	  },
	  
      onClose() {
        this.$refs.tooltip.$emit('close')
	  },
	  
 	showModal(bild) {
		 //console.log(bild)
		 this.modalProjekt=bild
		this.$refs['my-modal'].show()
		
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['my-modal'].toggle('#toggle-btn')
	  },
	  
		scrollFix: function(hashbang)
		{
		location.href = hashbang;


		},
	
		// pathLong: r(key),

		importAll(r) {
		r.keys().forEach(key => (this.images.push({  pathShort: key.replace('./', '') })));
		},

		importAllProjects(r) {
			// console.log(r)
		r.keys().forEach(key => (this.projectsFull.push({  pathShort: key.replace('./', '') })));
		},
		
		importAllProjectsThumbs(r) {
		r.keys().forEach(key => (this.projectsThumb.push({  pathShort: key.replace('./', '') })));
		},

	  
		onSwiper(swiper) {
			// console.log(swiper)
		},
		onSlideChange() {
			// console.log('slide change')
		},
	},
	created(){

	}
	
	//
}
</script>

<style >



#footer-copyright {
	padding:5px 0;
	background:#337AB7;
	overflow:hidden;
	font-family:"Open Sans", sans-serif;
	margin: 0 auto;
	position:fixed;
	bottom: 0;
    left: 0;
	width: 100%;
	z-index: 1000;
}

.zentriert {
	text-align:center;

}

.impressum {	
color: #FFF !important;
}
 

.zentriert .router-link-exact-active{
	color: #FFF !important;
}

.zentriert .router-link-active{
	color: #FFF !important;
}

.zentriert a{

	color:#FFF;
	font-size:11px;
	text-transform: uppercase;
}


    .subnav_shadow{
        height:auto;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 5%;
        padding-right: 5%;
        left: 0;
        right: 0;
    }

    .subnav{
        position:fixed;
        height:auto;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 5%;
        padding-right: 5%;
        border-bottom: 1px solid #6E6E6E;
        background-color: #F2F2F2;
        left: 0;
        right: 0;
        z-index: 880;
    }

    .list-inline_scoped>li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        line-height: 15px;
    }

.start-cards{

	min-width: 350px;
	max-width: 650px;
}

.job-nav-link{

	text-transform: uppercase !important;
	font-size: 1.0rem;
	font-weight: 600;
}

.modal-title{
	font-weight: 600 !important;
}
</style>

<style scoped>


		.team .card-title{
			text-transform: none !important;
			font-size: 1.0rem !important;
			font-weight: 500;
			white-space: nowrap;
		}

		.flip-box {
		background-color: transparent;
		width: 100%;
		min-height: 180px;
		/* border: 1px solid #f1f1f1; */
		 /* perspective: 1000px;Remove this if you don't want the 3D effect */
		}

		/* This container is needed to position the front and back side */
		.flip-box-inner {
		position: relative;
		width: 100%;
		min-height: 180px;
		text-align: center;
		transition: transform 0.8s;
		transform-style: preserve-3d;
		}

		/* Do an horizontal flip when you move the mouse over the flip box container */
		.flip-box:hover .flip-box-inner {
		transform: rotateY(180deg);
		}

		/* Position the front and back side */
		.flip-box-front, .flip-box-back {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-backface-visibility: hidden; /* Safari */
		backface-visibility: hidden;
		}

		/* Style the front side (fallback if image is missing) */
		.flip-box-front {
		/* background-color: #bbb; */
		color: black;
		}

		/* Style the back side */
		.flip-box-back {
		opacity: 0.8;
		background: white !important;
		color: black;
		transform: rotateY(180deg);
		}

		.top-left {
		position: absolute;
		top: 8px;
		left: 16px;
		}

		.ueberschrift .card-title
		{

		font-size: 2.0rem;
		text-transform: uppercase;
		font-weight: 700;

		}

		.buero .card-title{

		font-size: 1.1rem

		}

		.projekte img {
			width:100%;
			height:90%;
		object-fit: cover;
		cursor: pointer;
		}

		@media (max-width: 830px) {

			.projekte img {
			height:80%;
			}


		}

		@media (max-width: 950px) {

			.projekte img {
			height:85%;
			}


		}


.anchor{
	margin-top: 50px !important;
	padding-top: 35px;
}


h4.card-title {
font-size: 1.7rem;
font-weight: 600;

}

h4.team-card-title {

font-size: 1.1rem

}

.projekte .list-group-item {
	width: 96%;
	margin: 2% !important;
}

@media (min-width: 700px) {
	.projekte .list-group-item {
		width: 47%;
		margin: 5px 1.5% !important;
	}
}

@media (min-width: 1150px) {
	.projekte .list-group-item {
		width: 31.3%;
		margin: 5px 0.5% !important;
	}
}

@media (min-width: 1450px) {
	.projekte .list-group-item {
		width: 23%;
		margin: 0.5% !important;
	}
}

@media (min-width: 1850px) {
	.projekte .list-group-item {
		width: 18%;
		margin: 0.1% !important;
	}
}

@media (min-width: 2000px) {
	.projekte .list-group-item {
		width: 15%;
		margin: 0.1% !important;
		max-width:175px;

	}
}

		.team img {

		height:170px;
		object-fit: cover;
		cursor: pointer;
		}

.team {
	hyphens: auto;
}

.team .list-group-item {
	min-height:16em;
	max-height:18em;
	width: 70%;
	margin: 8% !important;
	max-width:14em;
}

@media (min-width: 576px) {
	.team .list-group-item {
		width: 47%;
		margin: 5px 1.5% !important;
	}
}

@media (min-width: 650px) {
	.team .list-group-item {
		width: 32.3%;
		margin: 0.5% !important;

	}
}

@media (min-width: 992px) {
	.team .list-group-item {
		width: 23%;
		margin: 0.5% !important;
	}
}

@media (min-width: 1200px) {
	.team .list-group-item {
		width: 18%;
		margin: 0.1% !important;
	}
}

/* @media (min-width: 1200px) {
	.team .list-group-item {
		width: 15%;
		margin: 0.1% !important;
		max-width:175px;

	}
} */
.team .card-body{

	padding: 0.5rem 0.5rem;

}


.card-team{


    object-fit: cover;
    /* border: 5px solid black; */
}

.card-img-top
{

	overflow-y: hidden;
}

.card-footer{
	padding: 0rem 0.25rem ;
	position:absolute;
	bottom:0px;
	width:100%;
	z-index: 1000;
	height: 10%;
	font-size:1em;
	font-weight:300;
	overflow-y: hidden;
}


@media (max-width: 830px) {

	.card-footer{
		height: 20%;

	}
}

@media (max-width: 950px) {
	.card-footer{
		height: 15%;

	}
}

.team-card{
max-width: 10rem;
min-width: 5rem;
}

.cryptedmail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld); 
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.buero{
	padding-left: 25px;
	text-align:left;
}

.job{
	padding-left: 20%;
	text-align:left;
}
.navbar_mobile {
  overflow: hidden;
  background-color: #333;  
  position: fixed;
  bottom: 0;

  width: 100%;
  z-index: 10000000;
}

.navbar_mobile a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.navbar_mobile a:hover {
  background-color: #ddd;
  color: black;
}

.navbar_mobile a.active {
  background-color: #4CAF50;
  color: white;
}

.navbar_mobile .iconNav {
  display: none;
  width: 60px;
  height: 60px;;
}

@media screen and (max-width: 600px) {

  .navbar_mobile a {display: none;} /*a:not(:first-child) */

  .navbar_mobile a.iconNav {
    float: right;
    display: block;

  }


  .navbar_mobile a.router-link-active {
    float: left;
    display: block;
     background-color:#333 !important;
     color:rgb(139, 215, 245) !important;
  }

}

@media screen and (max-width: 600px) {
  /* .navbar_mobile.responsive_mobile a.router-link-active {display: none;} */
  .navbar_mobile.responsive_mobile .iconNav {
    position: absolute;
    right: 0;
    bottom:0;
        
  }
  .navbar_mobile.responsive_mobile a {
    float: none;
    display: block;
    text-align: left;
  }

}



/* router-link-active */
.router-link-active{
	/* color:#337AB7 !important; */
    /* border-bottom: 1px solid #000; */
}

.router-link-exact-active{
	/* color:#337AB7 !important; */
    /* border-bottom: 1px solid #000; */
}

.router-link-exact-active:hover {
	cursor: default ;
    text-decoration:none;
}


.navbar
{
    padding: 0.5rem 0rem;
    height: 54px;
    background-color:#fff;
}

.navbar-nav{
    margin-left:4.5%;
    margin-right:4.5%;
}

.navbar-nav >  li{
    font-size:1.0em;
    font-weight:400;
	letter-spacing:0.1em;
	color: #000;
	display: block;
	text-transform: uppercase;
	border: 1px solid transparent;
}

a.nav-link.active{
    border-bottom: 11px solid #F2F2F2;
    color: #191970 !important;
}

.clear_bottom a{
    border-bottom: 11px solid transparent;
}

.navbar-collapse{
        background-color:#fff;
}

.navbar-toggler{
        margin-left:4.5%;
}

.navbar-light .navbar-nav .nav-link{
    color:#000;
}

.project-details-liste{
        margin-right:1em;
        padding-right:1.2em;
        border-right: 1px solid #A4A4A4;
}

.hr_line
    {
        border-bottom: 1px solid #A4A4A4;
        margin-left:-10px;
        margin-right:-10px;
        margin-bottom:7px;
    }
    
.projectDetails
    {
        font-weight: 600;
        margin-top: 5px;
        padding-left: 5px;
        padding-top: 5px;
    }
    
.abstandhalter_leer{
       height:54px;
}
    
.subnav_scoped {
	background: #fff;
	padding: 0;
	position: relative;
	text-align: left;
	width: 100%;
	min-height: 35px;
	z-index: 9987;
	border-top: 1px solid #F2F2F2;
}



.leere_zeile{
	height:1em;
}


/* .loggedIn  .router-link-active{
}

.router-link-active{
	color:#337AB7 !important;
} */

 .subtitle {
	 /* background-color: white;
	 opacity: 0.2; */
	font-size: 1.5em !important;
	font-weight: 50;
	letter-spacing: 2px;
	color:#fff;

	text-shadow: 0.02em 0.02em 0 rgba(0,0,0,0.1);
    line-height: 1.6em;
	
        bottom: 20px;
        right: 20px;

        position: absolute;

        z-index: 9999;
        color: #FFFFFF;


    }

 .title {

	font-size: 3.0em !important;
	font-weight: 50;
	letter-spacing: 4px;
	color:#000;
	text-shadow: 0.02em 0.02em 0 rgba(0,0,0,0.1);
    line-height: 1.6em;
	
        top: 20px;
        left: 20px;

        position: absolute;
        width: 100%;
        z-index: 9999;
        color: #FFFFFF;
        font-size: 0.75em;
        margin-bottom: 50px;
    }

@media (max-width: 735px) {

    .title{
    font-size: 2.0em !important;
    }
}

@media (max-width: 600px) {

    .title{
    font-size: 1.5em !important;
    }
}


.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.swiper-slide img {
  object-fit: cover;
  width: 100%;
  min-height: 100%;
}

.swiper-container{

height:100%;

}

.hintergrund{
	margin-top: 54px;
  height:100%;
}

.kontainer{

  height:100%;
}

.willkommen{
height:90%;
margin-left: 40px;
margin-right: 40px;
	}

.willkommenalt{
height:90%;
margin-left: 40px;
margin-right: 40px;
	  /* Location of the image */
  background-image: url(../../public/images/edeka_1.jpg);
  
  /* Image is centered vertically and horizontally at all times */
  background-position: center center;
  
  /* Image doesn't repeat */
  background-repeat: no-repeat;
  
  /* Makes the image fixed in the viewport so that it doesn't move when 
     the content height is greater than the image height */
  background-attachment: fixed;
  
  /* This is what makes the background image rescale based on its container's size */
  background-size: cover;
  
  /* Pick a solid background color that will be displayed while the background image is loading */
  background-color:#464646;
  
  /* SHORTHAND CSS NOTATION
   * background: url(background-photo.jpg) center center cover no-repeat fixed;
   */
}

#logo {
    width:100%;
    text-align:center;
    margin: 0 auto;
    margin-bottom:60px;
    padding-top:15px;
}


.logo_retz
{
	font-size: 3.0em;
	font-weight: 50;
	letter-spacing: 4px;
	color:#000;
	text-shadow: 0.02em 0.02em 0 rgba(0,0,0,0.1);
    line-height: 1.6em;
}

/* #0 1DF3A*/
/* #E EC7BC*/

.logo_retz_unterschrift
{
	font-size: 1.3em;
	font-weight: 100;
	letter-spacing: 2px;
	color:#9e9e9e;
}

.logo{
    float:right;
    line-height: 53px;
    margin-right:5%;
    font-weight:600;
    font-size:1.2em
}

.logo p{
    margin-right:10px;
}

@media (max-width: 850px) {
    .logo{
    display:none;
    }
}




.welcome
{
	font-size: 1.3em;
	font-weight: 400;
	letter-spacing: 2px;
	color:#000;

}


.welcomeunterschrift
{
	font-size: 1.1em;
	font-weight: 100;
	letter-spacing: 1px;
	color:#3d3d3d;
}
</style>