import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import 'leaflet/dist/leaflet.css';
Vue.config.productionTip = false

Vue.mixin({
	data() {
		return {
	
			window: {
				width: 0,
				height: 0
			  },

		}
	},

	methods: {

		handleResize() {
			this.window.width = window.innerWidth;
			this.window.height = window.innerHeight;
			},

	},

	mounted() {

	},
	computed:
	{

		bearbeiten:
		{
			get()
			{
				return this.$store.getters.bearbeiten
			},

			set(value)
			{
				this.$store.commit('changeBearbeiten', value);
			}
		},

		itsMobile() {
			//console.log(navigator.userAgent);
			  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true
			  } else {
				return false
			  }
		},

	},

	created(){
		window.addEventListener('resize', this.handleResize)
		this.handleResize();
	},

	destroyed() {
		window.removeEventListener('resize', this.handleResize)
	},


})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
