import Vue from 'vue'
import VueRouter from 'vue-router'
import startseite from '../components/start.vue'

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path:'/start', component:startseite},
    { path:'/', redirect: '/start'},

  ]
})

export default router
