<template>
  <div id="app">


    	<router-view/>


  </div>
</template>

<script>



export default {


    components: {

	},

	methods: {



		
	},

	computed: {

	},
	
	mounted() {


	},
	
	created(){



	},



}

// overflow-y:scroll;
</script>


<style>

html {
    height: 100%; 
}

body {
	height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height:100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
